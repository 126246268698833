import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import $ from 'jquery'

export default class ContactPage extends React.Component {
  state= {
    name: "",
    email:"",
    phone:"",
    message:"",
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  submitToAPI = e => {
    e.preventDefault();
    if (!/[A-Za-z]{1}[A-Za-z]/.test(`${this.state.name}` )) {
      alert ("Name can not less than 2 char");
      return;
    }
    if (!/[0-9]{10}/.test(`${this.state.phone}`)) {
      alert ("Please enter valid mobile number");
      return;
    }
    if (`${this.state.email}`==="") {
      alert ("Please enter your email id");
      return;
    }
    if (!/.+@.+\.[A-Za-z]+$/.test(`${this.state.email}`)) {
      alert ("Please enter valid email address");
      return;
    }
    var mydata = {
       name : `${this.state.name}`,
       phone : `${this.state.phone}`,
       email : `${this.state.email}`,
       message : `${this.state.message}`
    };
    
    $.ajax({
      type: "POST",
      url : "https://wuplfi0f84.execute-api.ap-south-1.amazonaws.com/dev/notify",
      data: JSON.stringify(mydata),
      success: function () {
        // clear form and show a success message
        alert("Successful!");
        document.getElementById("contact-form").reset();
        window.location.reload();
      },
      error: function () {
        // show an error message
        alert("UnSuccessful! Please try again!");
      }
    });
  }
  render() {
    return (
      <Layout>
        <SEO title="Contact Us" />
        <h2>Contact Us</h2>
        <div class="row">
          <div class="column">
            <p>For all inquiries, </p>
            <p>
              Call us at  <a href="tel:+91-9836112120">+91 9836112120</a>
            </p>
            <p>
              Or send us an email using the form:
            </p>
          </div>
          <div class="column" style={{ borderLeft: '1px solid black', paddingLeft: '80px'}}>
            <form id="contact-form" onSubmit={this.submitToAPI}>
              <label>
                Name <input type="text" name="name" id="name" value={this.state.name} onChange={this.handleInputChange}/>
              </label>
              <label>
                Email 
                <input type="text" name="email" id="email" value={this.state.email} onChange={this.handleInputChange}/>
              </label>
              <br/>
              <label>
                Phone <input type="text" name="phone" id="phone" value={this.state.phone} onChange={this.handleInputChange}/>
              </label>
              <label>
                Message
                <textarea name="message" id="message" rows="5" value={this.state.message} onChange={this.handleInputChange}/>
              </label>
              <button type="submit">Send</button>
              <input type="reset" value="Clear" />
            </form>
          </div>
        </div> 
        
      </Layout>
    )
  }
  
}
